export const ROUTE_NAMES = {
  THEME: "theme",
  HOME: "home",
  LOGIN: "login",
  SIGNUP: "signup",
  BOTS: "bots",
  BOT_REPORTS: "bot-reports",
  BOT: "bot",
  BOT_ITEM: "bot-item",
  CONTENTS: "contents",
  CONTENT_REPORTS: "content-reports",
  SETTINGS: "settings",
  MESSAGES: "messages",
  FILES: "files",
  UNAUTHORIZED: "unauthorized",
  USERS: "users",
  PROFILE: "profile",
  NOT_FOUND: "not-found",
  ABOUT: "about",
  CONTENT_ITEM: "content-item",
  MEDIA: "media",
  FUNNELS: "funnels",
  COMMANDS: "commands",
  FUNNEL_EDITOR: "funnel-editor",
  INSTRUCTIONS: "instructions",
} as const;
