import type { Directive } from "vue";

export const highlight: Directive = {
  beforeMount(el, binding) {
    el.style.backgroundColor = binding.value;
  },
  updated(el, binding) {
    el.style.backgroundColor = binding.value;
  },
};
