import { useDark, useToggle } from "@vueuse/core";
import { watch } from "vue";

export function useTheme() {
  // Используем useDark из VueUse
  const isDark = useDark({
    selector: "html",
    attribute: "class",
    valueDark: "dark",
    valueLight: "",
  });

  const toggleDark = useToggle(isDark);

  // Сохраняем предпочтение пользователя
  watch(isDark, (newValue) => {
    localStorage.setItem("theme", newValue ? "dark" : "light");
  });

  return {
    isDark,
    toggleDark,
  };
}
