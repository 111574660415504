<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <!-- Антенна -->
    <path
      d="M12 2L12 4"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <!-- Голова -->
    <rect
      x="7"
      y="4"
      width="10"
      height="8"
      rx="2"
      stroke="currentColor"
      stroke-width="2"
      fill="none"
    />
    <!-- Глаза -->
    <circle cx="10" cy="8" r="1" fill="currentColor" />
    <circle cx="14" cy="8" r="1" fill="currentColor" />
    <!-- Тело -->
    <rect
      x="9"
      y="12"
      width="6"
      height="8"
      stroke="currentColor"
      stroke-width="2"
      fill="none"
    />
    <!-- Руки -->
    <path
      d="M6 14H9M15 14H18"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <!-- Ноги -->
    <path
      d="M10 20L10 22M14 20L14 22"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
