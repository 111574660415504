<template>
  <div class="bot-layout">
    <router-view />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.el-breadcrumb {
  margin-bottom: 20px;
}

:deep(.is-active) {
  font-weight: bold;
}
</style>
