import type { RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import { createBotBreadcrumb } from "@/router/utils/breadcrumb";
import { lazyLoad } from "@/router/utils/lazyLoad";
import { ROUTE_NAMES } from "@/constants/routes";
import { useBotsStore } from "@/stores/modules/bots";

export const botRoutes: RouteRecordRaw[] = [
  {
    path: "/bots",
    component: () => import("@/layouts/BotLayout.vue"),
    redirect: "/bots",
    meta: {
      requiresAuth: true,
      title: "Боты",
      breadcrumb: "Боты",
      icon: "BotIcon",
      hideInMenu: false,
    },
    children: [
      {
        path: "",
        name: ROUTE_NAMES.BOTS,
        component: lazyLoad("bot/PageList"),
        meta: {
          requiresAuth: true,
          title: "Боты",
          breadcrumb: "Боты",
          hideInMenu: true,
        },
      },
      {
        path: ":id",
        component: lazyLoad("bot/BotContainer"),
        props: true, // Передаем id как проп
        children: [
          {
            path: "", // /bots/:id
            name: ROUTE_NAMES.BOT_ITEM,
            component: lazyLoad("bot/PageItem"),
            meta: {
              requiresAuth: true,
              title: "Информация о боте",
              breadcrumb: createBotBreadcrumb("Детали бота"),
              hideInMenu: true,
            },
          },
          {
            path: "media", // /bots/:id/media
            name: ROUTE_NAMES.MEDIA,
            component: lazyLoad("bot/PageMedia"),
            meta: {
              title: "Файлы бота",
              breadcrumb: createBotBreadcrumb("Файлы"),
            },
          },
          {
            path: "settings", // /bots/:id/settings
            name: ROUTE_NAMES.SETTINGS,
            component: lazyLoad("bot/PageSettings"),
            meta: {
              title: "Настройки бота",
              breadcrumb: createBotBreadcrumb("Настройки"),
            },
          },
          {
            path: "reports",
            name: ROUTE_NAMES.BOT_REPORTS,
            component: lazyLoad("bot/BotReports"),
            meta: {
              title: "Аналитика рассылок",
              breadcrumb: createBotBreadcrumb("Отчеты по боту"),
            },
          },
          {
            path: "contents",
            props: true, // Передаем id как проп
            children: [
              {
                path: "", // /contents
                name: ROUTE_NAMES.CONTENTS,
                component: lazyLoad("bot/contents/PageList"),
                meta: {
                  title: "Список контента",
                  breadcrumb: (route: RouteLocationNormalized) => {
                    const store = useBotsStore();
                    const bot = store.bot;
                    return [
                      {
                        title:
                          bot?.first_name ||
                          bot?.userId ||
                          `Бот #${route.params.id}`,
                        path: `/bots/${route.params.id}`,
                      },
                      {
                        title: "Контент",
                        path: route.path,
                      },
                    ];
                  },
                },
              },
              {
                path: ":contentId",
                props: true, // Передаем id как проп
                children: [
                  {
                    path: "",
                    name: ROUTE_NAMES.CONTENT_ITEM,
                    component: lazyLoad("bot/contents/PageItem"),
                    meta: {
                      title: "Пост бота",
                      breadcrumb: (route: RouteLocationNormalized) => {
                        const store = useBotsStore();
                        const bot = store.bot;
                        return [
                          {
                            title:
                              bot?.first_name ||
                              bot?.userId ||
                              `Бот #${route.params.id}`,
                            path: `/bots/${route.params.id}`,
                          },
                          {
                            title: "Контент",
                            path: `/bots/${route.params.id}/contents`,
                          },
                          {
                            title: `Пост #${route.params.contentId}`,
                          },
                        ];
                      },
                    },
                  },
                  {
                    path: "reports",
                    name: ROUTE_NAMES.CONTENT_REPORTS,
                    component: lazyLoad("ContentReports"),
                    meta: {
                      title: "Аналитика по рассылке",
                      breadcrumb: (route: RouteLocationNormalized) => {
                        const store = useBotsStore();
                        const bot = store.bot;
                        return [
                          {
                            title:
                              bot?.first_name ||
                              bot?.userId ||
                              `Бот #${route.params.id}`,
                            path: `/bots/${route.params.id}`,
                          },
                          {
                            title: "Контент",
                            path: `/bots/${route.params.id}/contents`,
                          },
                          {
                            title: `Пост #${route.params.contentId}`,
                            path: `/bots/${route.params.id}/contents/${route.params.contentId}`,
                          },
                          {
                            title: "Рассылка",
                          },
                        ];
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "messages",
            name: ROUTE_NAMES.MESSAGES,
            component: lazyLoad("bot/PageMessages"),
            meta: {
              title: "Сообщения бота",
              breadcrumb: createBotBreadcrumb("Сообщения"),
            },
          },
          {
            path: "funnels",
            name: ROUTE_NAMES.FUNNELS,
            component: lazyLoad("bot/funnels/BotFunnels"),
            meta: {
              title: "Фуннель бота",
              breadcrumb: createBotBreadcrumb("Фуннель"),
            },
          },
          {
            path: "flow/:funnelId",
            name: ROUTE_NAMES.FUNNEL_EDITOR,
            component: lazyLoad("bot/funnels/VueFlow"),
            meta: {
              title: "Редактор функционала воронки",
              breadcrumb: createBotBreadcrumb("Редактор воронки"),
            },
          },
          {
            path: "commands",
            name: ROUTE_NAMES.COMMANDS,
            component: lazyLoad("bot/funnels/BotCommands"),
            meta: {
              title: "Команды бота",
              breadcrumb: createBotBreadcrumb("Команды"),
            },
          },
        ],
      },
    ],
  },
];
