<script setup lang="ts">
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
const router = useRouter();
const route = useRoute();

interface Breadcrumb {
  title: string;
  path: string;
  active?: boolean;
}

const breadcrumbs = computed<Breadcrumb[]>(() => {
  const { meta } = route;
  const result: Breadcrumb[] = [];

  // Добавляем "Боты" только если мы находимся в разделе ботов
  if (route.path.startsWith("/bots")) {
    result.push({
      title: "Боты",
      path: "/bots",
      active: route.path === "/bots",
    });
  }

  // Если есть функция для создания хлебных крошек
  if (meta.breadcrumb && typeof meta.breadcrumb === "function") {
    const customBreadcrumbs = meta.breadcrumb(route);
    result.push(...customBreadcrumbs);
  }
  // Если просто строка
  else if (meta.breadcrumb && typeof meta.breadcrumb === "string") {
    result.push({
      title: meta.breadcrumb,
      path: route.path,
      active: true,
    });
  }

  return result;
});
const handleBack = () => {
  router.back();
};
</script>

<template>
  <el-page-header v-if="route.path !== '/'" @back="handleBack">
    <template #breadcrumb>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">Главная</el-breadcrumb-item>
        <template v-for="(crumb, index) in breadcrumbs" :key="index">
          <el-breadcrumb-item
            :to="crumb.path"
            :class="{ 'is-active': crumb.active }"
          >
            {{ crumb.title }}
          </el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </template>

    <template #content>
      <div class="flex items-center justify-between w-full">
        <span class="text-large font-600 mr-3">
          {{ route.meta?.title || "Страница" }}
        </span>
        <slot name="actions"></slot>
      </div>
    </template>
  </el-page-header>
</template>
