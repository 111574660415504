import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Moon, Sunny } from "@element-plus/icons-vue";
import { useTheme } from "@/composables/useTheme";


export default /*@__PURE__*/_defineComponent({
  __name: 'ThemeSwitch',
  setup(__props) {

const { isDark, toggleDark } = useTheme();

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createBlock(_component_el_switch, {
    modelValue: _unref(isDark),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(isDark) ? (isDark).value = $event : null)),
    "inline-prompt": "",
    "active-icon": _unref(Moon),
    "inactive-icon": _unref(Sunny),
    onChange: _unref(toggleDark)
  }, null, 8, ["modelValue", "active-icon", "inactive-icon", "onChange"]))
}
}

})