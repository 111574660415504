import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between w-full" }
const _hoisted_2 = { class: "text-large font-600 mr-3" }

import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
interface Breadcrumb {
  title: string;
  path: string;
  active?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PageHeader',
  setup(__props) {

const router = useRouter();
const route = useRoute();

const breadcrumbs = computed<Breadcrumb[]>(() => {
  const { meta } = route;
  const result: Breadcrumb[] = [];

  // Добавляем "Боты" только если мы находимся в разделе ботов
  if (route.path.startsWith("/bots")) {
    result.push({
      title: "Боты",
      path: "/bots",
      active: route.path === "/bots",
    });
  }

  // Если есть функция для создания хлебных крошек
  if (meta.breadcrumb && typeof meta.breadcrumb === "function") {
    const customBreadcrumbs = meta.breadcrumb(route);
    result.push(...customBreadcrumbs);
  }
  // Если просто строка
  else if (meta.breadcrumb && typeof meta.breadcrumb === "string") {
    result.push({
      title: meta.breadcrumb,
      path: route.path,
      active: true,
    });
  }

  return result;
});
const handleBack = () => {
  router.back();
};

return (_ctx: any,_cache: any) => {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_el_page_header = _resolveComponent("el-page-header")!

  return (_unref(route).path !== '/')
    ? (_openBlock(), _createBlock(_component_el_page_header, {
        key: 0,
        onBack: handleBack
      }, {
        breadcrumb: _withCtx(() => [
          _createVNode(_component_el_breadcrumb, { separator: "/" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_breadcrumb_item, { to: { path: '/' } }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Главная")
                ])),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(breadcrumbs.value, (crumb, index) => {
                return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
                  key: index,
                  to: crumb.path,
                  class: _normalizeClass({ 'is-active': crumb.active })
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(crumb.title), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "class"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(route).meta?.title || "Страница"), 1),
            _renderSlot(_ctx.$slots, "actions")
          ])
        ]),
        _: 3
      }))
    : _createCommentVNode("", true)
}
}

})