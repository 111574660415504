import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import SideBar from "@/components/aside/SideBar.vue";
import ThemeHeader from "@/components/theme/ThemeHeader.vue";
import PageHeader from "@/components/theme/PageHeader.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "layout-container" }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(SideBar)
      ]),
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_header, null, {
            default: _withCtx(() => [
              _createVNode(ThemeHeader)
            ]),
            _: 1
          }),
          _createVNode(_component_el_main, null, {
            default: _withCtx(() => [
              _createVNode(PageHeader, null, {
                subtitle: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "page-subtitle")
                ]),
                actions: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "page-actions")
                ]),
                _: 3
              }),
              _createVNode(_component_router_view)
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})