/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetch-wrapper";
import { Bot, BotsState, LessonSettings } from "@/types/bot";
import { useAuthStore } from "./auth";
import { TelegramUserResponse } from "@/types/telegram";
import { API_URL } from "@/constants/api";
export const useBotsStore = defineStore("bots", {
  state: (): BotsState => ({
    bot: null,
    bots: [],
    loading: false,
    error: null,
    settings: null,
    files: [],
    meta: { currentPage: 1, perPage: 10 },
    perPages: [10, 20, 50, 100],
  }),

  getters: {
    getBot: (state) => state.bot,
    activeBots: (state) => state.bots.filter((bot) => bot.status === "active"),
    inactiveBots: (state) =>
      state.bots.filter((bot) => bot.status === "inactive"),
  },

  actions: {
    async fetchBots() {
      try {
        this.loading = true;
        const response = await fetchWrapper.get(`${API_URL}bots`);
        this.bots = response.data || [];
        return response;
      } catch (error: any) {
        this.error = error;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async fetchBot(id: number) {
      this.loading = true;
      try {
        const response = await fetchWrapper.get(`${API_URL}bots/${id}`);
        this.bot = response.data;
        return response;
      } catch (error: any) {
        console.log(error);
        this.error = error;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async createBot(botData: { name: string; token: string }) {
      try {
        this.loading = true;
        const response = await fetchWrapper.post(`${API_URL}bots`, botData);
        this.error = null;
        this.bots.push(response.data);
        return response;
      } catch (error: any) {
        this.error = error.error;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async deleteBot(id: number) {
      try {
        this.loading = true;
        const response = await fetchWrapper.delete(`${API_URL}bots/${id}`);
        if (response.success) {
          this.bots = this.bots.filter((bot) => bot.id !== id);
        } else {
          this.error = response.data;
        }
        return response;
      } catch (error: any) {
        this.error = error.error;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async updateBot(botData: Bot) {
      try {
        this.loading = true;
        const response = await fetchWrapper.put(
          `${API_URL}bots/${botData.id}`,
          botData
        );
        if (response.success) {
          const index = this.bots.findIndex((bot) => bot.id === botData.id);
          if (index !== -1) {
            this.bots[index] = response.data;
          }
        } else {
          this.error = response.message;
        }
        this.error = null;
        return response;
      } catch (error: any) {
        this.error = error.error;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async updateBotStatus(botId: number, newStatus: string) {
      try {
        this.loading = true;
        const endpoint = newStatus === "active" ? "start" : "stop";
        const url = `${API_URL}bots/${botId}/${endpoint}`;

        const response = await fetchWrapper.post(url);
        if (response.success) {
          // Обновляем статус бота в локальном состоянии
          const index = this.bots.findIndex((bot) => bot.id === botId);
          if (index !== -1) {
            this.bots[index].status = response.data.status;
          }
          this.error = null;
        }
        return response;
      } catch (error: any) {
        this.error = error.error;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async fetchBotSettings(botId: number) {
      this.loading = true;
      try {
        const response = await fetchWrapper.get(
          `${API_URL}bots/${botId}/settings`
        );
        this.settings = response.data;
        return response;
      } catch (error: any) {
        this.error = error;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async updateBotSettings(botId: number, settings: Partial<LessonSettings>) {
      try {
        this.loading = true;
        const response = await fetchWrapper.put(
          `${API_URL}bots/${botId}/settings`,
          settings
        );
        this.settings = response.data;
        return response.data;
      } catch (error: any) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async uploadFile(botId: number, file: File) {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch(`${API_URL}bots/${botId}/files`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${useAuthStore().accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw await response.json();
        }

        const result = await response.json();
        this.files.push(result);
        return result;
      } catch (error: any) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async getFiles(botId: number) {
      try {
        this.loading = true;
        const response = await fetchWrapper.get(
          `${API_URL}bots/${botId}/files`
        );
        this.files = response.data || [];
        return response.data;
      } catch (error: any) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async deleteFile(botId: number, fileId: number) {
      try {
        this.loading = true;
        await fetchWrapper.delete(`${API_URL}bots/${botId}/files/${fileId}`);
        this.files = this.files.filter((file) => file.id !== fileId);
      } catch (error: any) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async getChatUrl(
      botId: number,
      chatId: number
    ): Promise<TelegramUserResponse> {
      try {
        this.loading = true;
        const response = await fetchWrapper.get(
          `${API_URL}bots/${botId}/chat/${chatId}`
        );
        return response.data;
      } catch (error: any) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },
  },
});
