import { DirectiveBinding } from "vue";
import { useAuthStore } from "@/stores/modules/auth";

export const role = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const auth = useAuthStore();
    const requiredRole = binding.value;

    if (!auth.user || auth.user.role !== requiredRole) {
      el.style.display = "none";
    }
  },
};
