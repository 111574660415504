/* eslint-disable no-useless-catch */
import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetch-wrapper";
import router from "@/router";
import { API_URL } from "@/constants/api";

interface User {
  id: number;
  email: string;
  name?: string;
  role: "user" | "manager" | "admin";
  password?: string;
  telegramId?: number;
  telegramPhotoUrl?: string;
}

interface AuthState {
  user: User | null;
  accessToken: string | null;
  returnUrl: string | null;
  isInitialized: boolean;
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    user: JSON.parse(localStorage.getItem("user") || "null"),
    accessToken: localStorage.getItem("token"),
    returnUrl: null,
    isInitialized: false,
  }),

  getters: {
    isAuthenticated: (state) => !!state.user,
    userRole: (state) => state.user?.role,
  },

  actions: {
    async init() {
      try {
        const token = localStorage.getItem("token");
        const user = JSON.parse(localStorage.getItem("user") || "null");

        if (token && user) {
          // Здесь можно добавить проверку валидности токена на бэкенде
          try {
            // const response = await fetchWrapper.get(
            //   `${API_URL}auth/validate`
            // );
            const response = { valid: true };

            if (response.valid) {
              this.user = user;
              this.accessToken = token;
            } else {
              // Если токен невалиден - очищаем данные
              this.clearAuth();
            }
          } catch (error) {
            this.clearAuth();
          }
        } else {
          this.clearAuth();
        }
      } finally {
        this.isInitialized = true;
      }
    },

    async login(email: string, password: string) {
      try {
        const response = await fetchWrapper.post(`${API_URL}auth/login`, {
          email,
          password,
        });

        // Обновляем состояние
        const { token, ...user } = response.data;
        this.user = user;
        this.accessToken = token?.token;
        console.log(this.user, this.accessToken);

        // Сохраняем в localStorage
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token?.token);

        // Редирект на предыдущую страницу или домой
        router.push(this.returnUrl || "/");
      } catch (error) {
        throw error;
      }
    },

    async logout(silent = false) {
      // Если silent = true, не делаем запрос на сервер
      if (!silent) {
        try {
          await fetchWrapper.post(`${API_URL}auth/logout`);
        } catch (error) {
          console.error("Logout error:", error);
        }
      }

      // Очищаем состояние
      this.clearAuth();

      // Редирект на страницу логина
      router.push("/login");
    },

    clearAuth() {
      this.user = null;
      this.accessToken = null;
      this.returnUrl = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },

    async register(email: string, password: string) {
      try {
        const response = await fetchWrapper.post(`${API_URL}auth/register`, {
          email,
          password,
        });

        // Проверяем успешность регистрации
        if (!response.data || response.data.error) {
          throw new Error(response.data?.error || "Ошибка при регистрации");
        }

        return response;
      } catch (error) {
        // Прокидываем ошибку выше для обработки в компоненте
        throw error;
      }
    },

    async checkTelegramLink() {
      try {
        const response = await fetchWrapper.post(
          `${API_URL}auth/telegram-binding`
        );

        return response;
      } catch (error) {
        throw error;
      }
    },

    async me() {
      try {
        const response = await fetchWrapper.get(`${API_URL}auth/me`);
        if (response.data?.id) {
          this.user = response.data;
          localStorage.setItem("user", JSON.stringify(response.data));
          console.log(response.data);
        } else {
          throw new Error("Не удалось получить данные пользователя");
        }
      } catch (error) {
        throw error;
      }
    },
  },
});
