import type { RouteLocationNormalized } from "vue-router";
import { useBotsStore } from "@/stores/modules/bots";

export const createBotBreadcrumb = (title: string) => {
  return (route: RouteLocationNormalized) => {
    const store = useBotsStore();
    const bot = store.bot;

    return [
      {
        title: bot?.first_name || bot?.userId || `Бот #${route.params.id}`,
        path: `/bots/${route.params.id}`,
      },
      {
        title,
        path: route.path,
      },
    ];
  };
};
