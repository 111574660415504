import { useAuthStore } from "@/stores/modules/auth";

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
  patch: request("PATCH"),
};

function request(method: string) {
  return async (url: string, body?: unknown) => {
    const requestOptions: RequestInit = {
      method,
      headers: authHeader(url),
    };
    if (body) {
      requestOptions.headers = {
        ...requestOptions.headers,
        "Content-Type": "application/json",
      };
      requestOptions.body = JSON.stringify(body);
    }

    try {
      const response = await fetch(url, requestOptions);
      return handleResponse(response);
    } catch (error) {
      return Promise.reject({
        status: 0,
        message: "Network error",
        data: error,
      });
    }
  };
}

function authHeader(url: string): HeadersInit {
  const { accessToken } = useAuthStore();
  const isApiUrl = url.startsWith(process.env.VUE_APP_API_URL);

  if (accessToken && isApiUrl) {
    return { Authorization: `Bearer ${accessToken}` };
  }
  return {};
}

async function handleResponse(response: Response) {
  try {
    const contentType = response.headers.get("content-type");
    let result;

    // Парсим ответ
    if (contentType && contentType.includes("application/json")) {
      const text = await response.text();
      try {
        result = JSON.parse(text);
      } catch (e) {
        throw new Error("Invalid JSON response");
      }
    } else {
      result = await response.text();
    }

    // Стандартизируем успешный ответ
    if (response.ok) {
      return {
        success: true,
        data: result.data || result,
        message: result.message || "Success",
        meta: result.meta || null,
      };
    }

    // Обработка ошибок
    const { logout } = useAuthStore();

    if (
      [401, 403].includes(response.status) &&
      !response.url.includes("/auth/logout")
    ) {
      logout(true);
    }

    // Стандартизируем ответ с ошибкой
    return Promise.reject({
      success: false,
      error: result.message || result.error || "Unknown error",
      data: result.data || null,
      meta: result.meta || null,
    });
  } catch (error) {
    console.error("Handle response error:", error);
    return Promise.reject({
      success: false,
      error: "Failed to process response",
      data: null,
      meta: null,
    });
  }
}
