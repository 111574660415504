import type { RouteRecordRaw } from "vue-router";
import { ROUTE_NAMES } from "@/constants/routes";
import { lazyLoad } from "../../utils/lazyLoad";
export const authRoutes: RouteRecordRaw[] = [
  {
    path: "/login",
    name: ROUTE_NAMES.LOGIN,
    component: lazyLoad("auth/SignIn"),
    meta: {
      public: true,
      layout: "AuthLayout",
    },
  },
  {
    path: "/signup",
    name: ROUTE_NAMES.SIGNUP,
    component: lazyLoad("auth/SignUp"),
    meta: {
      public: true,
      layout: "AuthLayout",
    },
  },
  {
    path: "/unauthorized",
    name: ROUTE_NAMES.UNAUTHORIZED,
    component: lazyLoad("Unauthorized"),
    meta: { public: true },
  },
];
