import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useAuthStore } from "@/stores/modules/auth";
import { ROUTE_NAMES } from "@/constants/routes";

export async function authGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const auth = useAuthStore();

  if (!auth.isInitialized) {
    await auth.init();
  }

  if (!to.meta.public && !auth.isAuthenticated) {
    auth.returnUrl = to.fullPath;
    return next("/login");
  }

  if (to.meta.roles && auth.user) {
    const hasRequiredRole = (to.meta.roles as string[]).includes(
      auth.user.role
    );
    if (!hasRequiredRole) {
      return next({ name: ROUTE_NAMES.UNAUTHORIZED });
    }
  }

  if (auth.isAuthenticated && to.path === "/login") {
    return next("/");
  }

  next();
}
