import type { RouteRecordRaw } from "vue-router";
import { ROUTE_NAMES } from "@/constants/routes";
import { lazyLoad } from "../../utils/lazyLoad";
export const adminRoutes: RouteRecordRaw[] = [
  {
    path: "/users",
    name: ROUTE_NAMES.USERS,
    component: lazyLoad("users/UsersList"),
    meta: {
      requiresAuth: true,
      title: "Пользователи",
      breadcrumb: "Пользователи",
      icon: "User",
      hideInMenu: false,
      roles: ["admin"],
    },
  },
];
