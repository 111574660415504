<script setup lang="ts">
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/modules/auth";
import { ElLoading } from "element-plus";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import BotLayout from "@/layouts/BotLayout.vue";

const route = useRoute();
const authStore = useAuthStore();
const isLoading = ref(true);

const layout = computed(() => {
  return route.meta.layout || "DefaultLayout";
});

// Создаем экземпляр loading сервиса
const loadingInstance = ElLoading.service({
  fullscreen: true,
  lock: true,
});

// Инициализируем auth store и ждем завершения
const initAuth = async () => {
  try {
    await authStore.init();
  } finally {
    isLoading.value = false;
    loadingInstance.close(); // Закрываем loading после инициализации
  }
};

// Запускаем инициализацию при монтировании компонента
initAuth();
</script>

<template>
  <!-- Показываем контент только после проверки авторизации -->
  <template v-if="!isLoading">
    <component
      :is="
        !authStore.isAuthenticated && !route.meta.public
          ? AuthLayout
          : layout === 'AuthLayout'
          ? AuthLayout
          : layout === 'BotLayout'
          ? BotLayout
          : DefaultLayout
      "
    >
      <router-view />
    </component>
  </template>
</template>

<style scoped>
/* Можно удалить стили loading-container, так как теперь используем Element Plus Loading */
</style>
