import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/modules/auth";
import { ElLoading } from "element-plus";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import BotLayout from "@/layouts/BotLayout.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppLayout',
  setup(__props) {

const route = useRoute();
const authStore = useAuthStore();
const isLoading = ref(true);

const layout = computed(() => {
  return route.meta.layout || "DefaultLayout";
});

// Создаем экземпляр loading сервиса
const loadingInstance = ElLoading.service({
  fullscreen: true,
  lock: true,
});

// Инициализируем auth store и ждем завершения
const initAuth = async () => {
  try {
    await authStore.init();
  } finally {
    isLoading.value = false;
    loadingInstance.close(); // Закрываем loading после инициализации
  }
};

// Запускаем инициализацию при монтировании компонента
initAuth();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (!isLoading.value)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(
        !_unref(authStore).isAuthenticated && !_unref(route).meta.public
          ? AuthLayout
          : layout.value === 'AuthLayout'
          ? AuthLayout
          : layout.value === 'BotLayout'
          ? BotLayout
          : DefaultLayout
      ), { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})